import React, { useState, useEffect } from "react"
import { getPlatformVariables } from "../../../api/public/platform_vars"
import { getVortexProducts } from "../../../api/public/vortex/product_service"
import { getVortexTokenBase } from "../../../api/public/vortex/token_service"
import socket from "../../../services/socketio"

export const IsloadingProducts = React.createContext(false)

export const VortextProducts = React.createContext([])

export const VortexContextError = React.createContext({
  isError: false,
  message: "",
})

export const ReloadProductsTrigger = React.createContext(false)

//Global state of dirham equivalent
export const PlatformVariables = React.createContext({})

const VortexContext = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false)

  const [platformVariables, setPlatformVariables] = useState({})

  const [error, setErrorData] = useState({ isError: false, message: "" })

  const [vortexProductData, setVortexProductData] = useState([])

  const [retry, setRetry] = useState(false)


  function loadPlatformVariables() {
    getPlatformVariables().then((result) => {
      console.log("platformVariables", result[0])
      setPlatformVariables(result[0])
    }).catch(error => {
      alert(error)
    })
  }


  const loadVortexProducts = async () => {
    setIsLoading(true)
    let vortexTokenResponse = await getVortexTokenBase()

    if (vortexTokenResponse.status === 200) {
      let vortextTokenResult = await vortexTokenResponse.json()
      let response = await getVortexProducts(vortextTokenResult.access_token)

      setIsLoading(false)
      if (response.status === 200) {
        let result = await response.json()

        setVortexProductData(result)

        loadPlatformVariables()
      } else {
        let result = await response.json()
        setIsLoading(false)
        setErrorData({
          isError: true,
          message: result.error.message,
        })
      }
    } else {
      let vortextTokenResult = await vortexTokenResponse.json()
      setIsLoading(false)
      setErrorData({
        isError: true,
        message: vortextTokenResult.error.message,
      })
    }
  }


  useEffect(() => {
    loadVortexProducts()

  }, [retry])

  useEffect(() => {
    socket.on(`receive-new-platformvars`, (data) => {
      console.log("platformVariables", data[0])
      setPlatformVariables(data[0])
    })
  }, [])




  return (
    <ReloadProductsTrigger.Provider value={[retry, setRetry]}>
      <VortexContextError.Provider value={[error, setErrorData]}>
        <IsloadingProducts.Provider value={[isLoading, setIsLoading]}>
          <VortextProducts.Provider
            value={[vortexProductData, setVortexProductData]}
          >
            <PlatformVariables.Provider value={[platformVariables, setPlatformVariables]}>
              {children}
            </PlatformVariables.Provider>
          </VortextProducts.Provider>
        </IsloadingProducts.Provider>
      </VortexContextError.Provider>
    </ReloadProductsTrigger.Provider>
  )
}

export default VortexContext
